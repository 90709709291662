<template>
  <div class="offer-validity" v-if="order.basket">
    <h4>{{ $t(`${tPath}.title`) }}</h4>
    <b-alert variant="danger" :show="!orderValidation.isValid">
      <h2 class="h5 alert-heading"><lazy-icon icon="b-exclamation-circle"/> {{ $t(`${tPath}.errors.title`) }}</h2>

      <div v-for="(error, eIndex) in orderValidation.validationErrors" :key="`OrderValidationError_${eIndex}`" class="error-group">
        {{ error[$store.getters['gui/language:get']] }}
      </div>
    </b-alert>

    <b-form id="OfferValidityForm" class="controls-form" novalidate>
      <b-form-row class="mb-5">
        <b-col v-bind="{ [$root.mt.key]: 12 }">
            <b-form-group aria-describedby="Control_OfferValidUntil"
                    :label="$t(`${tPath}.offervaliduntil.label`)"
                    label-for="Control_OfferValidUntil"
                    label-class="font-bold"
                    class="mr-md-2">
                    <b-form-datepicker id="Control_ValidUntilDate"
                      name="Control_ValidUntilDate"
                      form="OfferValidityForm"
                      :label-calendar="$t(`${tPath}.calendar.title`)"
                      :label-close-button="$t(`${tPath}.calendar.close.button`)"
                      :label-current-month="$t(`${tPath}.calendar.current.month`)"
                      :label-help="$t(`${tPath}.calendar.help`)"
                      :label-nav="$t(`${tPath}.calendar.nav`)"
                      :label-next-month="$t(`${tPath}.calendar.next.month`)"
                      :label-prev-month="$t(`${tPath}.calendar.prev.month`)"
                      :label-next-decade="$t(`${tPath}.calendar.next.decade`)"
                      :label-prev-decade="$t(`${tPath}.calendar.prev.decade`)"
                      :label-next-year="$t(`${tPath}.calendar.next.year`)"
                      :label-prev-year="$t(`${tPath}.calendar.prev.year`)"
                      :label-no-date-selected="$t(`${tPath}.calendar.no.date.selected`)"
                      :label-reset-button="$t(`${tPath}.calendar.reset.button`)"
                      :label-selected="$t(`${tPath}.calendar.selected`)"
                      :label-today="$t(`${tPath}.calendar.today`)"
                      :label-today-button="$t(`${tPath}.calendar.todaybutton`)"
                      v-model="quoteValidUntilDate"
                      v-bind:hide-header="true"
                      v-bind:start-weekday="$store.getters['gui/datetime:getWeekStartsOn']"
                      v-bind:min="new Date()"
                      />
            </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>

    <slot name="footer" v-bind="{ basketId: order.basket.id, submit }" />
  </div>
</template>

<script>
export default {
  name: 'OfferValidity',
  data () {
    return {
      quoteValidUntilDate: null
    }
  },
  computed: {
    order () {
      return this.$store.getters['checkout/get']
    },
    orderConfiguration () {
      return Object.assign({}, this.basket.configuration ?? {}, this.$store.getters['checkout/getConfiguration'] ?? {})
    },
    orderValidation () {
      return this.$store.getters['checkout/getValidation']
    }
  },
  methods: {
    submit (e) {
      e.preventDefault()

      const orderData = {
        orderType: 'Quote',
        quoteValidUntil: this.quoteValidUntilDate
      }

      this.$store.dispatch('checkout/execute', orderData)
        .then(response => {
          if (this.order.isOrderValid) {
            window.location.assign(e.target.href)
          }
        })
        .catch(response => {
        })
    }
  },
  created () {
    this.$store.dispatch('checkout/get')
  }
}
</script>

<style lang="scss">
.offer-validity {
  min-height: 500px;
}
</style>
