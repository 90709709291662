<template>
  <div class="signature-pad" v-if="order.basket">
    <h4>{{ $t(`${tPath}.title`) }}</h4>
    <b-alert variant="danger" :show="!orderValidation.isValid">
      <h2 class="h5 alert-heading"><lazy-icon icon="b-exclamation-circle"/> {{ $t(`${tPath}.errors.title`) }}</h2>

      <div v-for="(error, eIndex) in orderValidation.validationErrors" :key="`OrderValidationError_${eIndex}`" class="error-group">
        {{ error[$store.getters['gui/language:get']] }}
      </div>
    </b-alert>
    <div class="canvas-container mb-5">
      <canvas id="SignatureCanvas" width="600" height="400"></canvas>
    </div>

    <slot name="footer" v-bind="{ basketId: order.basket.id, submit }" />
  </div>
</template>

<script>
import SignaturePad from 'signature_pad'

export default {
  name: 'SignaturePad',
  data () {
    return {
      signaturePad: null
    }
  },
  computed: {
    order () {
      return this.$store.getters['checkout/get']
    },
    orderConfiguration () {
      return Object.assign({}, this.basket.configuration ?? {}, this.$store.getters['checkout/getConfiguration'] ?? {})
    },
    orderValidation () {
      return this.$store.getters['checkout/getValidation']
    }
  },
  methods: {
    submit (e) {
      e.preventDefault()

      const orderData = {
        orderType: 'Order',
        customerSignatureData: this.signaturePad.toDataURL()
      }

      this.$store.dispatch('checkout/execute', orderData)
        .then(response => {
          if (this.order.isOrderValid) {
            window.location.assign(e.target.href)
          }
        })
        .catch(response => {
        })
    }
  },
  created () {
    this.$store.dispatch('checkout/get')
  },
  mounted () {
    const self = this
    const interval = setInterval(initializeSignaturePad, 250)

    function initializeSignaturePad () {
      const canvas = document.getElementById('SignatureCanvas')
      if (canvas !== null) {
        // Set the canvas width to 100%
        const container = canvas.parentElement
        canvas.width = container.clientWidth

        self.signaturePad = new SignaturePad(canvas)

        // Get the drawing context of the canvas
        const ctx = canvas.getContext('2d')

        // Draw a white background on the canvas
        ctx.fillStyle = '#FFFFFF'
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        clearInterval(interval)
      }
    }
  }
}
</script>

<style lang="scss">
.signature-pad {
  .canvas-container {
    border: 1px solid $primary;
    line-height: 0;
  }
}
</style>
