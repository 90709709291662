import http from '@/$plugins/http'
import store from '@/$plugins/store/core'

export default {
  async searchByQuery(query, page = 1, pageSize = 10) {
    const result = await http({
      method: 'post',
      url: 'search/api/Users/SearchByQueryString',
      data: {
        language: store.getters['gui/language:get'],
        queryString: query,
        page: page,
        pageSize: pageSize,
        sort: [{ field: '_score', descending: true }]
      }
    })

    return result.data.result || []
  }
}
